<style scoped>
  .dialog-bottom-type{
    padding: 0 0 10px;
  }
  .dialog-bottom-box .dialog-bottom-type h3{
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #e5e5e5;
  }
  .dialog-type-box{
    padding-bottom: 10px;
  }
  .dialog-type-box ul.look-type{
    padding: 20px 15px;
  }
  .dialog-type-box .btn-bottom{
    width: 50%;
    font-size: 1.1rem;
  }
</style>
<template>
  <div class="dialog-bg" @click="dialogCloseBottom">
    <div class="dialog-bottom-box">
      <div class="dialog-bottom-type" @click.stop="dialogShowBottom">
        <h3>请选择表现形式</h3>
        <div class="dialog-type-box">
          <ul class="look-type">
            <li v-for="(item, index) in showTypeInfo" :class="showTypeIndex === index ? 'on' : ''" :key="index" @click="sltType(index)">
              <div><img :src="item.showImg" width="160"></div>
              <p>{{item.showName}}</p>
            </li>
          </ul>
          <div class="center"><button class="btn-bottom" @click="sureType">确定</button></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['showTypeInfo', 'showTypeIndex'],
  data () {
    return {
      // showTypeIndex: 0
      // showTypeInfo: [
      //   {
      //     showImg: require('../../assets/image/type01.gif'),
      //     showName: '半身像模式'
      //   },
      //   {
      //     showImg: require('../../assets/image/type02.gif'),
      //     showName: '胸像模式'
      //   },
      //   {
      //     showImg: require('../../assets/image/type03.gif'),
      //     showName: '网聊模式'
      //   }
      // ]
    }
  },
  components: {
  },
  mounted () {},
  methods: {
    sltType (index) {
      this.$emit('sltTypeFn', index)
    },
    dialogCloseBottom () {
      this.$emit('dialogCloseBottom')
    },
    dialogShowBottom () {
      this.$emit('dialogShowBottom')
    },
    sureType () {
      this.$emit('sureType')
    }
  }
}
</script>
