<style scoped>
  input:disabled,
  textarea:disabled,
  button:disabled {
    opacity: 0.7;
  }
.header {
  position: sticky!important;
  top: 0;
}
  .btn-bottom-box button:disabled {
    background: #9fa5f1;
    opacity: 1;
    color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 10px 3px rgba(199, 203, 254, 0.5);
  }

  .item-cover-box {
    overflow: hidden;
  }

  .item-cover-box .item-cover-img {
    float: left;
  }

  .item-cover-box .item-book-status {
    margin-left: 120px;
  }

  .item-cover-box .item-book-status p {
    line-height: 24px;
    margin-bottom: 5px;
    font-size: 0.95rem;
  }

  .form-item textarea {
    padding: 10px;
    height: 101px;
  }

  .Creative_mode {
    margin-bottom: 45px;
    overflow: hidden;
  }

  .Creative_mode_title {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
  }

.Creative_mode_img{
  background-color: rgba(0,0,0,0);
  margin: 0;
  padding: 0;
}
  .Creative_mode_img>img {
    height: 56px;
    width: 50px;
    float: left;
    margin-top: 11px;
    margin-right: 11px;
  }

  .Create_staff_1 {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    border: 1px solid #FFFFFF;
    vertical-align: bottom;
  }

  .Create_staff_4 {
    border: none;
    width: 54px;
    height: 22px;
    margin-left: 3px;
    vertical-align: bottom;
  }

  .Create_staff_1:nth-of-type(1) {
    position: relative;
    z-index: 4;
  }

  .Create_staff_1:nth-of-type(2) {
    position: relative;
    z-index: 3;
    left: -6px;
  }

  .Create_staff_1:nth-of-type(3) {
    position: relative;
    z-index: 2;
    left: -12px;
  }

  /* 弹窗开始 */
  .menubox {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }

  .isbtn0_btn1 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background: #547CFF;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    color: #FFFFFF;
    height: 29px;
    position: relative;
    z-index: 2;
    box-shadow: 0px 0px 0px 7px #fff;
    border: 0px;
  }

  .release_dialong {
    background-color: #fff;
    width: 300px;
    height: 156px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .release_dialong_img {
    height: 31px;
  }

  .release_dialong_img>img {
    width: 60px;
    height: 66px;
    position: absolute;
    top: -35px;
    left: 50%;
    margin-left: -30px;
  }

  .release_dialong_title {
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    color: #202020;
    text-align: center;
    margin-top: 3px;
  }

  .release_dialong_cont {
    padding: 0 30px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #202020;
    opacity: 1;
    margin-top: 17px;
  }

  .choose {
    border-top: #E7E9EE 1px solid;
    float: left;
    width: 49.838541%;
    ;
    text-align: center;
    padding: 12px 0;
    font-size: 15px;
    font-weight: 400;
    color: #547CFF;
  }

  .choose:nth-of-type(1) {
    border-right: #E7E9EE 1px solid;
  }

  .release_dialong_choose {
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    bottom: -22px;
  }

  .choose1 {
    font-size: 15px;
    font-weight: 400;
    color: #9B9EA3;
  }

  .release_true {
    width: 154px;
    height: 40px;
    background: #547CFF;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
    margin-top: 11px;
  }
.pagebgimg{
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100%;
height: 100%;
  }
  .pagebgimg>img{
    width: 100%;height: 100%;
  }
  .inputstyll{
   background: rgba(255,255,255,0.5);
   border: initial;
  }
  /* 弹窗结束 */
  /* @media screen and (max-width: 366px) {
      body {
          background-color:lightblue;
      }
  } */
</style>
<template>
  <div class="book-edit">
    <header-title :titleCon="titleCon" :titleBtnStr="complete" @titleLeftBtn="titleLeftListener" @titleFnBtn="titleFnListener">
    </header-title>
    <div class="content book-edit-form form-block">
      <div class="form-item">
        <label>作品名称</label>
        <input class="inputstyll" type="text" v-model="bookInfo.bookName" placeholder="请填写作品名称" maxlength="15" :disabled="isNoEditBook"/>
        <!-- <input class="inputstyll" v-show="isAuthor === 1 && isVerify === 0" type="text" v-model="bookInfo.bookName" placeholder="请填写作品名称" maxlength="15" /> -->
      </div>
      <div class="form-item">
        <label>添加封面</label>
        <div class="item-cover-box">
          <div class="item-cover-img">
            <div v-if="bookInfo.cover.coverImg===''">
              <a @click="goChangeFace"><img src="../../assets/image/face_add.png" width="107"></a></div>
            <div v-else>
              <a v-if="!isNoEditBook" @click="goChangeFace"><img :src="bookInfo.cover.coverImg" width="107" /></a>
              <a v-else><img :src="bookInfo.cover.coverImg" width="107" /></a>
            </div>
          </div>
          <div v-if="isEditChapter" class="item-book-status">
            <p>作品字数：{{bookInfo.sumWords}}</p>
            <p>
              状态：
              <span v-if="isVerify===0">未发布</span>
              <span v-if="isVerify===1 || isVerify===3">预发布</span>
              <span v-if="isVerify===2">已发布</span>
            </p>
            <p class="Create_staff" v-show="isCreate_staff === '1'">
              共创人员：
            </p>
            <div v-show="isCreate_staff === '1'">
              <img v-for="item in ugcBookCreatorList" :key="item.index" @click="Create_staff_page" class="Create_staff_1" :src="item.namePath" alt="">
              <img @click="Create_staff_page" v-show="Create_num1 != 3" class="Create_staff_1" src="../../assets/image/Create_staff_more.png" alt="">
              <img class="Create_staff_4" v-show="Create_num1 < 3 && isAuthor == 1" @click="Invite_members" src="../../assets/image/invitation.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="form-item">
        <label>作品简介</label>
        <textarea class="inputstyll" v-model="bookInfo.bookIntro" placeholder="请填写作品简介" rows="5" maxlength="90" :disabled="isNoEditBook" />
        <!-- <textarea v-show="isAuthor === 1 && isVerify === 0" class="inputstyll" v-model="bookInfo.bookIntro" placeholder="请填写作品简介" rows="5" maxlength="90" /> -->
      </div>
      <div class="Creative_mode" v-show="Creative_mode_show">
        <div class="Creative_mode_title">
          创作模式
        </div>
        <button class="Creative_mode_img" v-show="img1 == 0" @click="img1_switch" :disabled="isNoEditBook">
          <img src="../../assets/image/Creative_mode1.1.png" alt="">
        </button>
        <button class="Creative_mode_img" v-show="img1 == 1" @click="img1_switch" :disabled="isNoEditBook">
          <img src="../../assets/image/Creative_mode1.png" alt="">
        </button>
        <button class="Creative_mode_img" v-show="img2 == 0" @click="img1_switch1" :disabled="isNoEditBook">
          <img src="../../assets/image/Creative_mode2.2.png" alt="">
        </button>
        <button class="Creative_mode_img" v-show="img2 == 1" @click="img1_switch1" :disabled="isNoEditBook">
          <img src="../../assets/image/Creative_mode2.png" alt="">
        </button>
      </div>
      <div v-if="!isEditChapter">
        <div class="form-item">
          <label>初始章节名称</label>
          <input class="inputstyll" type="text" v-if="isEditChapter" v-model="bookInfo.startChapterName" maxlength="15" readonly disabled placeholder="请填写章节名称" />
          <input class="inputstyll" type="text" v-else v-model="bookInfo.startChapterName" maxlength="15" :disabled="isNoEditBook" placeholder="请填写章节名称" />
        </div>
        <!--<div class="form-item">
        <!<label>初始章节表现形式</label>-->
        <!--<ul class="look-type">-->
        <!--<li v-for="(item, index) in showTypeInfo" :class="showType === index ? 'on' : ''" :key="index">-->
        <!--<div v-if="!isEditChapter" @click="changeShowType(index)"><img :src="item.showImg" width="160"></div>-->
        <!--<div v-else><img :src="item.showImg" width="160"></div>-->
        <!--<p>{{item.showName}}</p>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</div>-->
        <div class="form-item form-item-type">
          <label>初始章节表现形式 <span class="fr gt" @click="changeShowType">{{showTypeInfo[showType].showName}}</span></label>
          <ul class="look-type">
            <li><img :src="showTypeInfo[showType].showImg" width="160"></li>
          </ul>
        </div>
      </div>
      <div class="form-btn btn-bottom-box">
        <button class="btn-bottom" @click="titleFnListener" :disabled="isNoEditBook">完成</button>
      </div>
    </div>
    <!-- 审核通知 -->
    <div v-show="isrelease_dialong">
      <div class="menubox">
        <div class="release_dialong">
          <div class="release_dialong_img">
            <img src="../../assets/image/releaseimage.png" alt="">
          </div>
          <!-- <div class="release_dialong_title">
            审核通知
          </div> -->
          <div class="release_dialong_cont">
            {{release_dialong_cont}}
          </div>
          <div class="release_true" @click="release_true">
            确定
          </div>
          <!-- <div class="release_dialong_choose">
            <div class="choose choose1" @click="iknow">
              确定
            </div>
            <div class="choose" @click="Work_management">
              再想想
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <dialog-bottom-type v-if="dialogBottomTypeShow" :showTypeInfo="showTypeInfo" :showTypeIndex="showTypeIndex"
      @sltTypeFn="sltTypeFn" @sureType="sureType" @dialogShowBottom="dialogShowBottom"
      @dialogCloseBottom="dialogCloseBottom">
    </dialog-bottom-type>
    <!-- <invitation v-bind:invitation_show="false" v-on:Modify="Modify($event)" v-show="invitation_show"></invitation> -->
  <div class="pagebgimg">
        <img :src="pagebg" alt="">
  </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
import dialogBottomType from '../../components/dialog/dialog-bottom-type.vue'
// import invitation from '../../components/share/invitation_share.vue'
import qs from 'qs'
export default {
  components: {
    headerTitle,
    dialogBottomType
    // invitation
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      isReClick: true,
      isNoEditBook: false, // true为审核或发布状态，禁用输入框
      isEditChapter: false, // true为作品编辑页面
      titleCon: '创建作品',
      showTypeIndex: 0,
      showType: 0,
      dialogBottomTypeShow: false,
      showTypeInfo: [{
        showImg: require('../../assets/image/type01.gif'),
        showName: '半身像模式'
      },
      {
        showImg: require('../../assets/image/type02.gif'),
        showName: '胸像模式'
      },
      {
        showImg: require('../../assets/image/type03.gif'),
        showName: '网聊模式'
      }
      ],
      isVerify: 0,
      bookInfo: {
        bookId: '',
        bookName: '',
        sumWords: '',
        bookIntro: '追逐梦想，记录时光',
        cover: {
          coverId: '',
          coverImg: ''
        },
        startChapterName: '',
        // 点击制作进入的参数
        making: null
      },
      isrelease_dialong: false,
      release_dialong_cont: '',
      Create_num: '',
      ugcBookCreatorList: null,
      img1: 1,
      img2: 0,
      czmos: null,
      isCreate_staff: null,
      Creative_mode_show: false,
      invitation_show: false,
      complete: '完成',
      Create_num1: '',
      isAuthor: '',
      verifyNum: 0
      // coverId: ''
    }
  },
  computed: {

  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    // 点击制作进入的传参
    this.making = this.$route.query.making
    // 如果是新用户点击制作进入的情况
    if (this.making === 1) {
      // console.log(this.$route.query.ugcBookDefault)
      this.bookInfo.bookName = this.$route.query.ugcBookDefault.ugcBookName
      this.bookInfo.cover.coverImg = this.$route.query.ugcBookDefault.coverName
      this.bookInfo.bookIntro = this.$route.query.ugcBookDefault.intro
      this.bookInfo.startChapterName = this.$route.query.ugcBookDefault.chapterTitle
      this.bookInfo.cover.coverId = this.$route.query.ugcBookDefault.coverId
      // console.log(this.bookInfo.cover.coverId)
    } else {
      var that = this
      this.axios({
        method: 'get',
        url: '/v1/book/toEditPage',
        params: {
          ugcBookId: this.$route.query.bookId
        }
      }).then((res) => {
        // console.log(res)
        // console.log(this.$store.state.bookInfo)
        if (res.data.head.desc === '进入添加作品界面') {
          if (this.$store.state.bookInfo.cover.coverImg !== '') { // 封面列表返回保存作品正在编辑数据到本地
            this.bookInfo = this.$store.state.bookInfo
          }
        } else {
          that.isEditChapter = true
          const bookData = res.data.content.ugcBook
          // 是否为编辑页面
          if (this.$route.query.bookId) {
            this.titleCon = '作品信息'
            this.complete = ''
            this.Creative_mode_show = true // 显示创作模式
            document.title = this.titleCon
            this.verifyNum = res.data.content.ugcBook.verifyNum
            this.isVerify = res.data.content.ugcBook.isVerify
            // 创作模式的图标显示
            if (res.data.content.ugcBook.creativeMode === 1) {
              this.img2 = 1
              this.img1 = 0
              this.isCreate_staff = '1'
              this.czmos = res.data.content.ugcBook.creativeMode
            } else if (res.data.content.ugcBook.creativeMode === 0) {
              this.img2 = 0
              this.img1 = 1
              this.isCreate_staff = null
              this.czmos = res.data.content.ugcBook.creativeMode
            }
            if (res.data.content.ugcBook.verifyNum > 0) {
              this.isNoEditBook = false
            }
          }
          if (this.$store.state.bookInfo.cover.coverImg !== '') {
            this.bookInfo = this.$store.state.bookInfo
          } else {
            that.bookInfo = {
              bookId: bookData.id,
              bookName: bookData.ugcBookName,
              sumWords: bookData.sumWords,
              bookIntro: bookData.intro,
              cover: {
                coverId: bookData.coverId,
                coverImg: bookData.coverName
              },
              startChapterName: bookData.chapterTitle
            }
          }
          that.showType = bookData.showType
          localStorage.setItem('coverId', bookData.coverId)
        }
      })
    }
    this.creator_list()
  },
  methods: {
    // 获取共创成员列表
    creator_list () {
      this.axios({
        method: 'get',
        url: '/v1/ugcBookCreatorList',
        params: {
          ugcBookId: this.$route.query.bookId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          this.isAuthor = res.data.content.isAuthor
          if (res.data.content.ugcBookCreatorList === []) {
            this.Create_num = 0
            this.Create_num1 = 0
          } else if (res.data.content.ugcBookCreatorList.length !== 0) {
            this.Create_num = res.data.content.ugcBookCreatorList.length
            this.Create_num1 = res.data.content.ugcBookCreatorList.length
            this.ugcBookCreatorList = res.data.content.ugcBookCreatorList
          }
          // 判断输入框是否可以点击
          if (this.isAuthor === 1 && this.verifyNum === 0) {
            this.isNoEditBook = false
          } else if (this.isAuthor === 0 || this.verifyNum > 0) {
            this.Create_num = 3
            this.isNoEditBook = true
          }
          if (res.data.content.isAuthor === 0) {
            // this.isNoEditBook = true
            this.Create_num = 3
          }
        }
      })
    },
    // 创作模式图标切换
    img1_switch () {
      if (this.img1 === 0) {
        this.img1 = 1
        this.img2 = 0
        this.czmos = 0
        if (this.czmos === 0) {
          this.isCreate_staff = null
        }
      }
    },
    img1_switch1 () {
      if (this.img2 === 0) {
        this.img2 = 1
        this.img1 = 0
        this.czmos = 1
        if (this.czmos === 1) {
          this.isCreate_staff = '1'
        }
      }
    },
    // 满员确认框
    release_true () {
      if (this.release_dialong_cont === '共创人员最多只有三人哦~') {
        this.isrelease_dialong = false
      }
    },
    // 满员后点击邀请按钮提示框
    Invite_members () {
      this.axios({
        method: 'get',
        url: '/v1/ugcCreatorAddFlag',
        params: {
          ugcBookId: this.$route.query.bookId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          if (res.data.content.flag === 0) {
            this.isrelease_dialong = true
            this.release_dialong_cont = '共创人员只能有三人哦~'
          } else if (res.data.content.flag === 1) {
            window.location.href = '/invitedshare?bookId=' + this.$route.query.bookId + '&sUserId=' + res.data.content.userId
            // this.$router.push({
            //   path: '/invitedshare',
            //   query: {
            //     bookId: this.$route.query.bookId
            //     // sUserId: res.data.content.userId
            //   }
            // })
            // this.invitation_show = true
          }
        }
      })
    },
    // 点击成员头像跳转成员列表
    Create_staff_page () {
      this.$router.push({
        path: '/membersinvitation',
        query: {
          bookId: this.$route.query.bookId,
          verifyNum: this.verifyNum,
          isAuthor: this.isAuthor
        }
      })
    },
    // 返回（多次选择封面会造成封面页与本页返回跳转多次，故返回跳到指定页）
    titleLeftListener () {
      if (this.$route.query.bookId) { // 从章节管理跳入，跳回章节管理
        this.$router.push({
          path: '/chapterManage',
          query: {
            bookId: this.$route.query.bookId
          }
        })
      } else { // 首页
        this.$router.push({
          path: '/book'
        })
      }
      this.clearBookInfo()
    },
    // 添加封面
    goChangeFace () {
      this.$store.state.bookInfo = this.bookInfo
      if (this.making === 1) {
        this.$router.push({
          path: '/bookCover',
          query: {
            making: this.making,
            bookId: this.bookInfo.bookId
          }
        })
      } else {
        this.$router.push({
          path: '/bookCover',
          query: {
            bookId: this.bookInfo.bookId
          }
        })
      }
    },
    // 选择章节形式
    changeShowType () {
      this.dialogBottomTypeShow = true
      this.showTypeIndex = this.showType
    },
    // 点击第几个形式
    sltTypeFn (index) {
      this.showTypeIndex = index
      this.dialogBottomTypeShow = true
    },
    // 确定形象
    sureType () {
      this.showType = this.showTypeIndex
      this.dialogBottomTypeShow = false
    },
    // 底部弹框弹出
    dialogShowBottom () {
      this.dialogBottomTypeShow = true
    },
    // 底部弹框关闭
    dialogCloseBottom () {
      this.dialogBottomTypeShow = false
    },
    // 完成
    titleFnListener () {
      if (this.bookInfo.bookName === '') {
        this.$toast('请输入作品名称')
      } else if (this.bookInfo.bookName.length > 15) {
        this.$toast('作品名称不能超过15字')
      } else if (this.bookInfo.cover.coverImg === '') {
        this.$toast('请添加封面')
      } else if (this.bookInfo.startChapterName === '') {
        this.$toast('请输入初始章节名称')
      } else {
        // 分类接口
        // if (this.isReClick) {
        this.axios({
          method: 'post',
          url: '/v1/book/save',
          data: qs.stringify({
            ugcBookId: this.bookInfo.bookId,
            // authorId: sessionStorage.getItem('authorId'),
            ugcBookName: this.bookInfo.bookName,
            coverId: this.bookInfo.cover.coverId,
            intro: this.bookInfo.bookIntro,
            chapterTitle: this.bookInfo.startChapterName,
            showType: this.showType,
            creativeMode: this.czmos
          })
        }).then((res) => {
          // console.log(res)
          if (res.data.head.desc === '更新作品成功') {
            this.$router.push({
              path: '/book'
            })
            // this.$store.state.coverindex = 0
            this.clearBookInfo()
          } else if (res.data.head.desc === '共创人员不为空，无法修改创作模式为普通') {
            this.$toast('人数过多，无法修改')
          } else if (res.data.content.chapterId !== '') {
            if (this.making === 1) {
              this.$router.push({
                path: '/chapterAdd',
                query: {
                  making: 1,
                  bookId: res.data.content.ugcBookId,
                  chapterType: '0', // 章节类型（0：主章节，1：子章节）
                  chapterId: res.data.content.chapterId,
                  type: 'edit',
                  ugcBookDefault: this.$route.query.ugcBookDefault
                }
              })
            } else {
              this.$router.push({
                path: '/chapterAdd',
                query: {
                  bookId: res.data.content.ugcBookId,
                  chapterType: '0', // 章节类型（0：主章节，1：子章节）
                  chapterId: res.data.content.chapterId,
                  type: 'edit'
                }
              })
            }
          }
          localStorage.setItem('coverId', '')
          // this.isReClick = false
        })
        // }
        // else {
        //   this.$toast('重复点击了')
        // }
      }
    },
    // 清楚编辑图书信息
    clearBookInfo () {
      this.$store.state.bookInfo = {
        cover: {
          coverId: '',
          coverImg: ''
        },
        bookName: '',
        bookIntro: '',
        startChapterName: ''
      }
    }
  }
}
</script>
